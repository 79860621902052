// @flow

import React, { useCallback } from 'react';
import type { Node } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Row, Col } from '@riseart/antd-provider';
import { Button } from '@riseart/common';
import { Section, SectionContent, Wrapper } from '@riseart/layout';
import { FormBuilder, FormItem, FormSelect, FormSelectOption } from '@riseart/form';
import { FilterService } from '@riseart/filter-service';
import { ShortcutLinks } from 'shared_components/common/links/Shortcut';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';

import {
  cmsFiltersFormCls,
  cmsFiltersFormInputCls,
  cmsFiltersFormSubmitBtnCls,
} from 'shared_components/cms/modules/filters/Form.less';

type Props = {
  title: string | Node,
  subTitle: string | Node,
  buttonText: string,
  domain: string,
  selectors: Object[],
  disableHeadingTags?: boolean,
  buttons?: Array<{
    external: boolean,
    target?: string,
    text: string,
    href: string,
  }>,
};

/**
 * CmsModuleFiltersForm
 *
 * @param {Props} props
 * @returns {Node}
 */
export function CmsModuleFiltersForm({
  buttons,
  title,
  subTitle,
  buttonText,
  selectors,
  domain,
  disableHeadingTags = false,
}: Props): Node {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const handleSubmit = useCallback(
    (data) => {
      const filters = Object.keys(data).reduce((accumulator, key: string) => {
        const value = data[key];

        if (value) {
          const parsedValue = JSON.parse(value) || [];

          if (Array.isArray(parsedValue)) {
            parsedValue.forEach(({ dimension, value }) => {
              accumulator[dimension] = [...(accumulator[dimension] || []), value];
            });
          }
        }

        return accumulator;
      }, {});

      const filterConfig = getFilterDomainConfig(domain);
      const filterService = new FilterService(filterConfig, formatMessage, {});
      const url = filterService.assemble(filterService.validate(filters));

      if (url) {
        history.push(url);
      }
    },
    [domain, history, formatMessage],
  );

  return (
    <Section>
      <Wrapper>
        <SectionContent
          title={title}
          subTitle={subTitle}
          headingProps={{ tag: disableHeadingTags ? 'span' : 'h2', level: '2', align: 'center' }}
          headingSubTitleProps={{ tag: disableHeadingTags ? 'span' : 'h3', align: 'center' }}
          className={cmsFiltersFormCls}
        >
          <FormBuilder onSubmit={handleSubmit}>
            {({ form }) => (
              <Row type="flex" gutter={16}>
                {selectors.map(({ label, options }) => (
                  <Col key={label} xs={24} sm={6}>
                    <FormItem form={form} key={label} name={label}>
                      <FormSelect
                        size="large"
                        placeholder={label && label.toUpperCase()}
                        choiceTransitionName={null}
                        transitionName={null}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        className={cmsFiltersFormInputCls}
                      >
                        {options.map(({ label: optionLabel, filter }) => (
                          <FormSelectOption key={optionLabel} value={JSON.stringify(filter)}>
                            {optionLabel}
                          </FormSelectOption>
                        ))}
                      </FormSelect>
                    </FormItem>
                  </Col>
                ))}
                <Col xs={24} sm={6}>
                  <FormItem>
                    <Button
                      htmlType="submit"
                      size="medium"
                      width="container"
                      className={cmsFiltersFormSubmitBtnCls}
                    >
                      {buttonText}
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            )}
          </FormBuilder>
          <ShortcutLinks links={buttons} />
        </SectionContent>
      </Wrapper>
    </Section>
  );
}
